<template>
  <v-container>
    <v-card class="mx-auto" max-width="1250px" width="100%" elevation="2">
      <v-responsive :aspect-ratio="documentPageAspectRation">
        <v-card-text class="text-center">
          <DocumentPageLoader v-if="isLoading" />

          <div v-else-if="hasRevisionListContent" v-html="controlListContent"></div>

          <span v-else class="mx-auto headline">
            {{ $t('contract.checklist.error') }}
          </span>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-container>
</template>

<script>
import { authorizedRequest } from '@/services/ApiService'
import DocumentPageLoader from '@/common/skeletons/DocumentPageLoader'

export default {
  name: 'ContractDocumentRevisionList',
  components: {
    DocumentPageLoader,
  },
  data() {
    return {
      controlListContent: null,
      loaded: false,
    }
  },
  computed: {
    contractId() {
      return this.$route.params.contractId
    },
    documentPageAspectRation() {
      return 210 / 297
    },
    isLoading() {
      return !this.loaded
    },
    hasRevisionListContent() {
      return !!this.controlListContent
    },
  },
  mounted() {
    this.loaded = false

    authorizedRequest({
      method: 'GET',
      endpoint: `api/v1/contracts/${this.$route.params.contractId}/controllist/preview`,
    })
      .then((resp) => {
        this.controlListContent = resp?.html
      })
      .catch(() => {
        this.$notification.error(this.$t('contract.checklist.error'))
      })
      .finally(() => {
        this.loaded = true
      })
  },
}
</script>

<style lang="scss" scoped></style>
